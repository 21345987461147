/**
 * Interface for interest types
 */
export interface InterestType {
  [key: string]: string | string[] | boolean // Allows dynamic keys
  itemId: string
  interestTypeKey: string
  interestTypeName: string
  timeStamp: string
  version: string
}

/**
 * Usage type from Aws
 */
export interface GetUsageType {
  endDate: string
  items: { value: [number, number] }
  position: string
  startDate: string
  usagePlanId: string
}

/**
 * Random variables used in the app
 */
export const HOME_ROUTE_PATH = 'home'
export const LOGIN_ROUTE_PATH = 'login'
export const IS_RT_ADMIN_ROLE = 'rtAppAdmin'

/**
 * This is the timer used for closing the snackbar for saved/deleted roles/users.
 */
export const TIME_OUT = 3000

/**
 * Configuration mapping for interest rates and/or risk levels.
 * Defines the display values and keys for different time periods, risk categories etc.
 * Can be easily expanded with new time periods (or other stuff) when needed.
 */
export interface ColumnConfig {
  key: string
  value: string
}

// Data mapping for interest rates and risk levels,
// can be extended with additional time periods as needed
const dataMapping = [
  {key: 'risk1', value: 'Risk 1'},
  {key: 'risk2', value: 'Risk 2'},
  {key: 'risk3', value: 'Risk 3'},
  {key: 'risk4', value: 'Risk 4'},
  {key: 'eSpar', value: 'E-spar'},
  {key: '3', value: '3 mån'},
  {key: '6', value: '6 mån'},
  {key: '12', value: '1 år'},
  {key: '18', value: '1,5 år'},
  {key: '24', value: '2 år'},
  {key: '36', value: '3 år'},
  {key: '48', value: '4 år'},
  {key: '60', value: '5 år'},
  {key: '72', value: '6 år'},
  {key: '84', value: '7 år'},
  {key: '96', value: '8 år'},
  {key: '108', value: '9 år'},
  {key: '120', value: '10 år'},
  {key: 'month3', value: '3 mån'},
  {key: 'year1', value: '1 år'},
  {key: 'year2', value: '2 år'},
  {key: 'year3', value: '3 år'},
  {key: 'year4', value: '4 år'},
  {key: 'year5', value: '5 år'},
  {key: 'year6', value: '6 år'},
  {key: 'year7', value: '7 år'},
  {key: 'year8', value: '8 år'},
  {key: 'year9', value: '9 år'},
  {key: 'year10', value: '10 år'},
  {key: 'disclaimerInterest', value: 'Disclaimer'}
]
export const generateColumnConfigs = (
  matchingConfig: InterestType | undefined
): ColumnConfig[] => {
  if (!matchingConfig) {
    return []
  }

  // Filter to keep only the keys that have a value of true
  const trueKeys = Object.entries(matchingConfig)
    .filter(([, value]) => value === true)
    .map(([key]) => key)

  // Filter dataMapping to preserve the original order
  return dataMapping
    .filter(item => trueKeys.includes(item.key))
    .map(item => ({
      key: item.key,
      value: item.value
    }))
}